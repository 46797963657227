import React, { useState } from 'react'
import { OrderValueRecord } from '../../services/RefundCalculatorService'
import { toDateTime } from '../../utils'
import './CreditNotesTable.scss'

interface Props {
    remainingValueRecord: OrderValueRecord
    historyRefunds: OrderValueRecord[]
    refundInProgress?: OrderValueRecord
}

const CreditNotesTable: React.FC<Props> = ({
    remainingValueRecord,
    historyRefunds,
    refundInProgress
}) => {
    const [showHistory, setShowHistory] = useState(false)

    // Get all unique product codes
    const allProducts = new Set<string>()
    remainingValueRecord.productValueRecords.forEach(p => allProducts.add(p.productCode))
    historyRefunds.forEach(r => r.productValueRecords.forEach(p => allProducts.add(p.productCode)))
    if (refundInProgress) {
        refundInProgress.productValueRecords.forEach(p => allProducts.add(p.productCode))
    }

    const productCodes = Array.from(allProducts).sort()

    const calculateTotal = (record: OrderValueRecord): number => {
        const productsTotal = record.productValueRecords.reduce((sum, p) => sum + p.value, 0)
        return productsTotal + (record.shippingCostValue ?? 0)
    }

    return (
        <div className="refund-table-container">
            <button 
                className="toggle-history" 
                onClick={() => setShowHistory(!showHistory)}
            >
                {showHistory ? 'Hide History' : 'Show History'}
            </button>

            <table className="refund-table">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="remaining">
                            Remaining
                            <div className="date">{toDateTime(remainingValueRecord.date)}</div>
                        </th>
                        {showHistory && historyRefunds.map(refund => (
                            <th key={refund.date?.toString()} className="history">
                                History
                                <div className="date">{toDateTime(refund.date)}</div>
                                <div className="reason">{refund.reason}</div>
                            </th>
                        ))}
                        {refundInProgress && (
                            <th className="in-progress">
                                In Progress
                                <div className="date">{toDateTime(refundInProgress.date)}</div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {productCodes.map(code => (
                        <tr key={code}>
                            <td>{code}</td>
                            <td>{findProductValue(remainingValueRecord, code)}</td>
                            {showHistory && historyRefunds.map(refund => (
                                <td key={refund.date?.toString()}>
                                    {findProductValue(refund, code)}
                                </td>
                            ))}
                            {refundInProgress && (
                                <td>{findProductValue(refundInProgress, code)}</td>
                            )}
                        </tr>
                    ))}
                    <tr>
                        <td>Shipping Costs</td>
                        <td>{remainingValueRecord.shippingCostValue}</td>
                        {showHistory && historyRefunds.map(refund => (
                            <td key={refund.date?.toString()}>
                                {refund.shippingCostValue}
                            </td>
                        ))}
                        {refundInProgress && (
                            <td>{refundInProgress.shippingCostValue}</td>
                        )}
                    </tr>
                    <tr className="total-row">
                    <td><strong>Total</strong></td>
                    <td><strong>{calculateTotal(remainingValueRecord).toFixed(2)}</strong></td>
                    {showHistory && historyRefunds.map(refund => (
                        <td key={refund.date?.toString()}>
                            <strong>{calculateTotal(refund).toFixed(2)}</strong>
                        </td>
                    ))}
                    {refundInProgress && (
                        <td><strong>{calculateTotal(refundInProgress).toFixed(2)}</strong></td>
                    )}
                </tr>
                </tbody>
            </table>
        </div>
    )
}

const findProductValue = (record: OrderValueRecord, productCode: string): number => {
    const product = record.productValueRecords.find(p => p.productCode === productCode)
    return product?.value ?? 0
}

export default CreditNotesTable